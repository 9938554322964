import React from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import ScannerTable from './ScannerTable';
import FilterDropdown from '../FilterDropdown/FilterDropdown';
import Accordion from '../Accordion/Accordion';
import AllFilters from '../Filters/AllFilters';
import MultiScreenRange from '../Filters/Fields/MultiScreenRange';
import Header from '../Header/Header';
import 'react-datepicker/dist/react-datepicker.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const Scanner = ({group, groupNumber, multiScreen, dispatch, hasChild, theme, setTheme, wsSocket}) => {
  
  const handleUpdateMultiSelect = (value, type, field) => {
    const {searchValue} = group;
    const newSearchValueCopy = {...searchValue};
    const findFieldToUpdate = newSearchValueCopy.filters.find((filter) => filter?.key === type);

    if (findFieldToUpdate && findFieldToUpdate.value) {
      const updateWith = {
        updatedValue: value
          ? [...findFieldToUpdate.value, field]
          : findFieldToUpdate.value.filter((filter) => filter !== field),
        key: type,
        minMax: null,
        group,
      };
      dispatch({
        type: 'UPDATE_FILTER_VALUES',
        payload: updateWith,
      });
    }
  };

  const hasFilters = () => {
    const filters = group?.searchValue?.filters;
    const withoutSearch = filters?.filter((filt) => filt?.filterable?.type !== 'search');
    const boolAndAdditionalFeatures =
      group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length).length ||
      group?.searchValue?.additionalFilters?.length ||
      group?.searchValue?.bearBullFilters?.length || 
      group?.showBearBullFilter;
    const multiScreenRange =
      multiScreen && withoutSearch?.filter((filter) => filter && filter?.filterable?.type === 'range')?.length;

    return withoutSearch?.length || boolAndAdditionalFeatures || multiScreenRange;
  };

  const handleChangePage = (event, newPage) => {
    if (!group?.group) return;
    dispatch({type: 'SET_PAGE', payload: {group, page: newPage}});
  };

  /**
   * Check if it is currently in trading hours
   * @returns Boolean
   */
  const isTradingHours = () => {
    const date = dayjs().tz('America/New_York');
    const startOfDay = dayjs().tz('America/New_York').startOf('date');

    const dayOfWeek = date.format('d');

    const difference = date.diff(startOfDay) / 1000;

    if (dayOfWeek >= 1 && dayOfWeek <= 5 && difference >= 34200 && difference <= 57600) {
      return true;
    }

    return false;
  };

  const getSymbolData = (symbol) => {
    if (wsSocket) {
      wsSocket.emit('chart:get', {symbol, group: group.group});
      dispatch({type: 'SET_SYMBOL_TO_CHART', payload: {symbol}});
    }
  };

  return (
    <div className={`${hasChild ? 'hasChild pb-7' : ''} scanner-wrap `}>
      <Header
        name={group?.groupTitle}
        dispatch={dispatch}
        group={group}
        groupNumber={groupNumber}
        filters={group?.searchValue?.filters}
        handleChangePage={handleChangePage}
        page={group?.page}
        count={Object.keys(group?.filteredData || {})?.length ?? 0}
        rowsPerPage={group?.rowsPerPage}
        theme={theme}
        setTheme={setTheme}
      />
      {group ? (
        <div className="scanner">
          {hasFilters() ? (
            <Accordion title="Filter Options">
            <div className="flex items-end flex-col flex-wrap lg:flex-row filter-btn my-1 filter-row gap-4 mx-auto group-header">
              {group?.searchValue?.filters?.filter((filt) => filt?.filterable?.type !== 'search')?.length ? (
                <AllFilters
                  filters={group?.searchValue?.filters}
                  group={group}
                  handleUpdateMultiSelect={handleUpdateMultiSelect}
                  dispatch={dispatch}
                  multiScreen={multiScreen}
                />
              ) : null}

              {group?.searchValue?.filters?.filter((filter) => filter && filter?.filterable?.type === 'range').length &&
              multiScreen ? (
                <div>
                  <MultiScreenRange
                    group={group}
                    dispatch={dispatch}
                    filters={group?.searchValue?.filters.filter(
                      (filter) => filter && filter?.filterable?.type === 'range',
                    )}
                  />
                </div>
              ) : null}

              {group?.searchValue?.boolFilters?.filter((field) => field?.fields?.length) ||
              group?.searchValue?.additionalFilters?.length ||
              group?.searchValue?.bearBullFilters?.length || 
              group?.showBearBullFilter ? (
                <div className="flex flex-col w-full gap-4">
                  <FilterDropdown
                    group={group}
                    handleUpdateMultiSelect={handleUpdateMultiSelect}
                    additionalFields={[
                      ...(group?.searchValue?.additionalFilters ?? []),
                      ...(group?.searchValue?.boolFilters ?? []),
                    ]}
                    boolFilters={group?.searchValue?.filters?.filter((filter) => filter.type === 'boolean') ?? []}
                    bearBullFilters={group?.searchValue?.bearBullFilters ?? []}
                    showBearBullFilter={group?.showBearBullFilter}
                    activeFilter={group?.activeFilter}
                    dispatch={dispatch}
                    multiScreen={multiScreen}
                  />
                </div>
              ) : null}
            </div>
            </Accordion>
          ) : null}
          <div className="flex justify-between mx-auto w-full delay-info">
            <p className="delay-info w-full ">
              <small>15 Minute Delay</small>
            </p>
            {!isTradingHours() ? (
              <p className="after-market-hours w-full text-right">
                <small>Data represents values from prior business day&apos;s close</small>
              </p>
            ) : null}
          </div>

          <ScannerTable
            tableData={group?.filteredData ?? {}}
            dataTypes={group?.dataTypes ?? []}
            orderBy={group?.orderBy}
            group={group}
            page={group?.page}
            multiScreen={multiScreen}
            order={group?.order}
            rowsPerPage={group?.rowsPerPage}
            dispatch={dispatch}
            handleChangePage={handleChangePage}
            getSymbolData={getSymbolData}
          />
        </div>
      ) : (
        <div className="scanner"> Loading Scanner... </div>
      )}
    </div>
  );
};

export default Scanner;
