import React, {useState, useEffect, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import CustomInputDate from './CustomInputDate';
// import Toggle from '../Toggles/Toggle';
// import AdModal from '../Modal/AdModal';
// import {ReactComponent as DarkPoolIcon} from '../../Icons/DarkPoolIcon.svg';
// import {ReactComponent as CalendarIcon} from '../../Icons/Calendar.svg';
// import {ReactComponent as FilterIcon} from '../../Icons/Filter.svg';
// import {ReactComponent as LineIcon} from '../../Icons/Line.svg';
// import {ReactComponent as SearchIcon} from '../../Icons/Search.svg';
// import {ReactComponent as BellIcon} from '../../Icons/Bell.svg';
// import {ReactComponent as SettingsIcon} from '../../Icons/SettingsMd.svg';
import {ReactComponent as ChevronLeftIcon} from '../../Icons/ChevronLeft.svg';
import {ReactComponent as ChevronRightIcon} from '../../Icons/ChevronRight.svg';
// import {ReactComponent as BookmarkIcon} from '../../Icons/Bookmark.svg';
// import {ReactComponent as StatsIcon} from '../../Icons/Stats.svg';
import {ReactComponent as SearchSmIcon} from '../../Icons/SearchSm.svg';

const moment = require('moment');

const Header = ({name, dispatch, filters, group, groupNumber, page, handleChangePage, rowsPerPage, count, theme, setTheme}) => {
  const [searchValue, setSearchValue] = useState('');
  const [atEnd, setIsAtEnd] = useState(false);
  const disableDays = (current) => {
    const june = moment().set({year: 2022, month: 4, date: 31}).toDate();
    const day = moment(current).day();
    return day !== 0 && day !== 6 && current >= june;
  };

  const formatDate = (date) => {
    const timestamp = Date.parse(date);
    if (Number.isNaN(timestamp)) return new Date();
    const selectedToMomentDay = moment(date, 'YYYY-MM-DD');
    const june = moment().set({year: 2022, month: 4, date: 31});
    const day = selectedToMomentDay.day();
    // If not weekend and after June 31st, return date
    if (day !== 0 && day !== 6 && !moment(selectedToMomentDay).isBefore(june)) {
      return selectedToMomentDay.toDate();
    }
    return new Date();
  };

  const handleDatePickerChange = (date) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    dispatch({type: 'CHANGE_GROUP_DATE', payload: {newDate, group}});
  };

  useEffect(() => {
    setIsAtEnd(rowsPerPage * (page + 1) > count);
  }, [page, count, rowsPerPage]);

  return (
    <div className="my-4 mx-auto group-header">
      <div className="flex justify-between">
        <div className="flex gap-4 items-center">
          {groupNumber > 1 ? <h2 className="text-header-color font-bold text-[20px] leading-[24px]">{name}</h2> : '' }
        </div>
        <div className="flex gap-7 pr-5">
          {group?.selectedDate && group?.showDateSelector ? (
            <div className="flex items-center">
              <DatePicker
                filterDate={disableDays}
                selected={formatDate(group?.selectedDate ?? null)}
                onChange={handleDatePickerChange}
                customInput={<CustomInputDate />}
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex justify-start pr-9 mt-4 gap-9">
        <div className="flex items-center relative">
          {filters
            ? filters
                .filter((filt) => filt?.filterable?.type === 'search')
                .map((filter) => (
                  <input
                    type="text"
                    className="relative rounded-[50px] border-none text-[12px] leading-[15px] text-[#70769B] py-2 pl-9 "
                    placeholder="Filter Tickers"
                    style={{background: 'rgba(148, 196, 242, 0.1)'}}
                    key={filter?.key}
                    onChange={(e) => {
                      dispatch({
                        type: 'UPDATE_FILTER_VALUES',
                        payload: {
                          updatedValue: e.target.value,
                          key: filter?.key,
                          minMax: null,
                          group,
                        },
                      });
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue || ''}
                    name={filter?.key}
                    id={filter?.key}
                  />
                ))
            : null}

          <SearchSmIcon className="text-[#70769B] absolute left-4 top-1/2 -translate-y-1/2" />
        </div>
        <button
          type="button"
          disabled={!page}
          onClick={(e) => (page ? handleChangePage(e, page - 1) : null)}
          className={`${!page && 'opacity-60 cursor-default'} flex flex-col items-center pagination-button p-1`}
        >
          <ChevronLeftIcon className="text-[#70769B]" />
          <p className="text-[12px] leading-[15px] text-[#70769B] mt-1">Prev</p>
        </button>
        <button
          type="button"
          disabled={atEnd}
          onClick={(e) => (!atEnd ? handleChangePage(e, page + 1) : null)}
          className={`${atEnd && 'opacity-60 cursor-default'} flex flex-col items-center pagination-button p-1`}
        >
          <ChevronRightIcon className="text-[#70769B]" />
          <p className="text-[12px] leading-[15px] text-[#70769B] mt-1">Next</p>
        </button>
      </div>
    </div>
  );
};

export default Header;
