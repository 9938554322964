import React, {useState, useEffect} from 'react';
import ReactDOM from "react-dom/client";
import { Routes, Route } from "react-router-dom";
import {applyTheme} from './themes/utils';
import Dashboard from "./Pages/Dashboard";
import Home from "./Pages/Home";
import Scanners from "./Pages/Scanners";
import AccessDenied from "./Pages/AccessDenied";
import Logout from "./Pages/Logout";
import NotFound from "./Pages/NotFound";

import './App.css';

const {REACT_APP_STANDALONE} = process.env;

const App = () => {
  console.log('REACT_APP_STANDALONE', REACT_APP_STANDALONE);
  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');
  useEffect(() => {
    applyTheme(theme);
  }, [theme]);

  return (
    <div className={`${theme} flex h-full bg-main-bg`}>
      <Routes>
        <Route path="/" element={<Dashboard theme={theme} setTheme={setTheme} />}>
          <Route index element={<Home />} />
          <Route path="/scanners/:id" element={<Scanners theme={theme} setTheme={setTheme} />} />
        </Route>
        <Route path="/logout" element={<Logout />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
