import React from 'react';

const LoadingUser = (props) => {
  const messageData = props;
  const message = messageData.message ? messageData.message : 'Loading...';
  return (
    <div id="loading-user" className="flex w-full h-full fixed justify-center items-center flex-wrap">
      <div className="loading-user-wrapper">
        <div className="mb-2 text-center animate-pulse">
          {/* <img src="/mml-avatar.png" alt="MML Icon" className="inline-block" /> */}
        </div>
        <span>{message}</span>
      </div>
    </div>
  );
};

export default LoadingUser;
