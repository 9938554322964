import React from 'react';
import { useOutletContext } from "react-router-dom";

const Home = ({theme, setTheme}) => {
  const [scannersData, userData, groupCollection, groups, isConnected, loadingGroups, updatedCount, wsSocket, dispatch] = useOutletContext();
  // const userPhrase = userData && userData.name ? `Hi ${userData.name}! `: "";
  const scannersPhrase = scannersData && scannersData.length ? "Please select a scanner from the menu to continue." : "Unfortunately, there are no scanners available.";

  return (
      <div id="page">
        <h1>Home</h1>
        <p>{scannersPhrase}</p>
      </div>
  );
};

export default Home;
