import React from 'react';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {ReactComponent as CloseIcon} from '../../Icons/Close.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 320,
  maxWidth: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 1,
};

const closeImg = {cursor:'pointer', position: 'absolute', top: '-20px', right: '-20px', width: '40px'};



const AdModal = ({open, handleClose, freeUser}) => 
 ( freeUser ? ( 
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>  
        <CloseIcon onClick={handleClose} style={closeImg}/>
        <div id='azk972030'>{' '}</div>
      </Box>
    </Modal>
  )
  : null );


export default AdModal;