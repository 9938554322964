/* This example requires Tailwind CSS v2.0+ */
import React, {Fragment} from 'react';

const JoinedBooleanField = ({field, handleValueChange}) => (
  <>
    <legend key={field.type} className="sr-only">
      Filter By
    </legend>
    <div className="relative flex justify-between" key={`${field.fieldKey ?? field.key}`}>
      <div className='flex text-xs text-header-color items-center'>{field.title}</div>
      <div className='flex'>
        {field?.filterable?.options.map((opt, i) => (
            <div className='flex items-center mx-2' key={`${field.fieldKey ?? field.key}-${i*3}`}>
                <input
                id={`${field.key}-${opt}`}
                name={`${field.key}-${opt}`}
                checked={field?.selected?.includes(opt) ?? false}
                type="checkbox"
                onChange={(e) => handleValueChange(opt, field.fieldKey ?? field.key)}
                className="focus:ring-transparent h-4 w-4 mr-1 text-sky-500 border-gray-300 rounded"
                />
                <label htmlFor={`${field.key}-${opt}`} className="checkbox-label whitespace-nowrap text-xs">
                {opt ? 'True' : 'False'}
                </label>
            </div>
            ))}
        </div>
      </div>
  </>
);


export default JoinedBooleanField;
