import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import {CSVLink, CSVDownload} from 'react-csv';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScannerTableHeader from './ScannerTableHeader';
import {getComparator, stableSort} from '../../Helpers/tableHelpers';
import ScannerItem from './ScannerItem';

const ScannerTable = ({
  tableData,
  page,
  dataTypes,
  orderBy,
  group,
  multiScreen,
  order,
  rowsPerPage,
  dispatch,
  handleChangePage,
  getSymbolData,
}) => {
  const [currentData, setCurrentData] = useState(tableData);

  useEffect(() => {
    const keys = Object.keys(tableData);
    const toArray = keys.reduce((acc, key) => [...(acc || []), tableData[key]], []);
    setCurrentData(toArray);
  }, [tableData, group.data, group.filteredData]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    dispatch({
      type: 'SET_ORDERBY',
      payload: {group, orderBy: property, order: isAsc ? 'desc' : 'asc'},
    });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: 'SET_ROWS_PER_PAGE',
      payload: {rowsPerPage: parseInt(event.target.value, 10), group},
    });
  };

  const toCSVData = (data) => {
    if (!data || !Array.isArray(data)) return [];

    const csv = [];
    csv.push(dataTypes.map(type => type.title));

    data.forEach(d => {
      csv.push(
        dataTypes.map(type => {
          const {key} = type;
          return d[key]?.value;
        })
      );
    });
    
    return csv;

  }

  // Avoid a layout jump when reaching the last page with empty tableData.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - group?.data?.length) : 0;

  return (
    <Box sx={{width: multiScreen ? '95%' : '100%', mx: 'auto'}}>
      <Paper sx={{width: '100%', mb: 2}}>
        <TableContainer>
          <Table sx={multiScreen ? {} : {minWidth: 750}} aria-labelledby="tableTitle" size="small">
            {dataTypes.length > 0 && (
              <ScannerTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeaders={dataTypes}
                multiScreen={multiScreen}
              />
            )}
            {currentData && currentData.length ? (
              <TableBody>
                {stableSort(currentData, getComparator(order, orderBy, dataTypes))
                  .filter((item) => item !== null)
                  .map((row, index) => (
                    <ScannerItem
                      row={row}
                      key={`${row?.Symbol}-${index * 3}`}
                      dataTypes={dataTypes}
                      type={group?.type}
                      group={group}
                      dispatch={dispatch}
                      multiScreen={multiScreen}
                      getSymbolData={getSymbolData}
                    />
                  ))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 33 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={1} />
                  </TableRow>
                )}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={dataTypes.length} align="center">
                    <h1>
                      {!Object.keys(group?.filteredData || {})?.length && Object.keys(!group?.data || {}).length
                        ? 'Come Back During Market Hours'
                        : 'No Results Found'}
                    </h1>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={Object.keys(group?.filteredData || {})?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={group?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CSVLink data={toCSVData(currentData)}><span className='text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active px-2 py-4 text-sm active'>Download CSV</span></CSVLink>
      </Paper>
    </Box>
  );
};
export default ScannerTable;
