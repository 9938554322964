import React from 'react';
import {NavLink} from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import {ReactComponent as HomeIcon} from '../../Icons/Home.svg';
import {ReactComponent as RoomIcon} from '../../Icons/Room.svg';
import {ReactComponent as BalanceScaleIcon} from '../../Icons/BalanceScale.svg';
import {ReactComponent as BankIcon} from '../../Icons/Bank.svg';
import {ReactComponent as BoltIcon} from '../../Icons/Bolt.svg';
import {ReactComponent as CalendarClockIcon} from '../../Icons/CalendarClock.svg';
import {ReactComponent as ChartHistogramIcon} from '../../Icons/ChartHistogram.svg';
import {ReactComponent as ChartNetworkIcon} from '../../Icons/ChartNetwork.svg';
import {ReactComponent as ChartPyramidIcon} from '../../Icons/ChartPyramid.svg';
import {ReactComponent as ChartSetTheoryIcon} from '../../Icons/ChartSetTheory.svg';
import {ReactComponent as BasketBusinessIcon} from '../../Icons/BasketBusiness.svg';
import {ReactComponent as EyeIcon} from '../../Icons/Eye.svg';
import {ReactComponent as WalletIcon} from '../../Icons/Wallet.svg';
import {ReactComponent as MembersIcon} from '../../Icons/Members.svg';
import {ReactComponent as MessagesIcon} from '../../Icons/Messages.svg';
import {ReactComponent as SettingsIcon} from '../../Icons/Settings.svg';
import {ReactComponent as LogOutIcon} from '../../Icons/LogOut.svg';
import {ReactComponent as RbLogoLight} from '../../Icons/RbLogoLight.svg';
import {ReactComponent as RbLogoDark} from '../../Icons/RbLogoDark.svg';
import {ReactComponent as MMLLogoLight} from '../../Icons/MMLLogoLight.svg';
import {ReactComponent as MMLLogoDark} from '../../Icons/MMLLogoDark.svg';

const Sidebar = (props) => {
  const navigation = [
    {name: 'Dashboard', icon: HomeIcon, href: '/', current: false},
    // {name: 'Room', icon: RoomIcon, href: '#', current: false},
  ];

  const secondary = [
    // {name: 'Members', icon: MembersIcon, href: '#', current: true},
    // {name: 'Messages', icon: MessagesIcon, href: '#', current: false},
    // {name: 'Settings', icon: SettingsIcon, href: '#', current: false},
    {name: 'Logout', icon: LogOutIcon, href: '/logout', current: false},
  ];

  const iconMap = {
    room: RoomIcon,
    balance: BalanceScaleIcon,
    bank: BankIcon,
    bolt: BoltIcon,
    'calendar-clock': CalendarClockIcon,
    'chart-histogram': ChartHistogramIcon,
    'chart-network': ChartNetworkIcon,
    'chart-pyramid': ChartPyramidIcon,
    'chart-set-theory': ChartSetTheoryIcon,
    eye: EyeIcon,
    wallet: WalletIcon,
   'basket-business': BasketBusinessIcon,
   EmojiEvents: EmojiEventsIcon,
   TrackChanges: TrackChangesIcon
  };

  const {theme, setTheme, scannersData} = props;
  const scannersNavigation = [];
  if (scannersData && scannersData.length) {
    scannersData.forEach((s) => {
      scannersNavigation.push({
        name: s.title,
        icon: iconMap[s.icon] ? iconMap[s.icon] : iconMap.room,
        href: `/scanners/${encodeURIComponent(s.slug)}`,
        current: false,
      });
    });
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <div
      style={{boxShadow: '5px 0px 10px rgba(0, 0, 0, 0.05)'}}
      className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-menu-background pt-5 pb-4 w-1/6 max-w-[270px] sidebar"
    >
      {/* <div
        className={`${
          theme === 'light' ? 'border-[#EBEBEB]' : 'border-[#70819C]'
        } flex flex-shrink-0 items-center pb-8 border-b  px-8`}
      >
        {theme === 'light' ? <MMLLogoLight /> : <MMLLogoDark />}
      </div> */}
      <div
        className={`${
          theme === 'light' ? 'border-[#EBEBEB]' : 'border-[#70819C]'
        } flex flex-grow flex-col px-8 border-b pb-5 `}
      >
        <nav className="flex-1 space-y-1 bg-menu-item" aria-label="Sidebar">
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                item.current
                  ? 'bg-menu-item-active text-menu-item-text-active border-2 border-menu-item-border-active'
                  : 'text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active hover:border-menu-item-border-active',
                'group flex items-center px-2 py-4 text-sm leading-[18px] font-bold rounded-md',
              )}
              end
            >
              <item.icon
                className={classNames(
                  item.current
                    ? 'text-menu-item-text-active'
                    : 'text-menu-item-text group-hover:text-menu-item-text-active',
                  'mr-3 flex-shrink-0 h-4 w-4',
                )}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
            </NavLink>
          ))}

          {scannersNavigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                item.current
                  ? 'bg-menu-item-active text-menu-item-text-active border-2 border-menu-item-border-active'
                  : 'text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active  hover:border-menu-item-border-active',
                'group flex items-center px-2 py-4 text-sm leading-[18px] leading- font-bold rounded-md',
              )}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? 'text-white'
                    : 'text-[#9AA2AC] group-hover:text-white',
                  'mr-3 flex-shrink-0 h-4 w-4',
                )}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
            </NavLink>
          ))}

          {secondary.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className={classNames(
                item.current
                  ? 'bg-menu-item-active text-menu-item-text-active border-2 border-menu-item-border-active'
                  : 'text-menu-item-text hover:bg-menu-item-active hover:text-menu-item-text-active  hover:border-menu-item-border-active',
                'group flex items-center px-2 py-4 text-sm leading-[18px] leading- font-bold rounded-md',
              )}
            >
              <item.icon
                className={classNames(
                  item.current
                    ? 'text-menu-item-text-active'
                    : 'text-menu-item-text group-hover:text-menu-item-text-active',
                  'mr-3 flex-shrink-0 h-4 w-4',
                )}
                aria-hidden="true"
              />
              <span className="flex-1">{item.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>

      {/* <div
        className={`${
          theme === 'light' ? '' : 'border border-[#70769B]'
        } bg-menu-item-active flex flex-col p-[18px] mx-8 mt-8 rounded-[10px]`}
      >
        <p className="text-white text-lg font-bold text-center">
          Go back to the Dashboard
        </p>
        <a href="/" className="mt-[18px]">
          <div className="text-sm font-bold bg-white text-center rounded-lg text-mml-green-100 py-3.5">
            &lt; Dashboard
          </div>
        </a>
      </div> */}
    </div>
  );
};

export default Sidebar;
