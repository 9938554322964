import React from 'react';

const Logout = () => {
  localStorage.removeItem('scanner-sso');
  return (
    <div id="page">
      <h1>You have successfully logged out.</h1>
      <p>
        To restart your session, please access from the{' '}
      </p>
    </div>
  );
};

export default Logout;
