/* eslint-disable no-param-reassign */

const moment = require('moment');

/**
 * Comperator function for sorting the table by the given column.
 *
 * @param {Object} a
 * @param {Object} b
 * @param {Boolean} orderBy
 * @returns
 */
const descendingComparator = (aValue, bValue, orderBy, dataTypes) => {
  const filterType = dataTypes?.find((type) => type.key === orderBy);
  const a = aValue ? aValue[orderBy]?.value ?? aValue[orderBy] : null;
  const b = bValue ? bValue[orderBy]?.value ?? bValue[orderBy] : null;
  if (filterType?.convertTo === 'time') {
    const aDate = a && a?.length ? a?.split(' ')[0] : '';
    // eslint-disable-next-line prefer-const
    let [hoursA, minutesA, secondsA] = aDate?.split(':') ?? [0, 0, 0];
    const dateA = new Date();
    if (a?.length && a?.includes('PM')) {
      if (hoursA !== '12') {
        hoursA = 12 + Number(hoursA);
      } else {
        hoursA = 12;
      }
    } else if (a?.length && a?.includes('AM')) {
      if (hoursA === '12') {
        hoursA = 0;
      } else {
        hoursA = Number(hoursA);
      }
    }
    dateA.setHours(hoursA);
    dateA.setMinutes(minutesA);
    dateA.setSeconds(secondsA);

    const bDate = b && b.length ? b?.split(' ')[0] : '';
    // eslint-disable-next-line prefer-const
    let [hoursB, minutesB, secondsB] = bDate?.split(':') ?? [0, 0, 0];

    const dateB = new Date();
    if (b?.length && b?.includes('PM')) {
      if (hoursB !== '12') {
        hoursB = 12 + Number(hoursB);
      } else if (hoursB === '12') {
        hoursB = 12;
      }
    } else if (b?.length && b?.includes('AM')) {
      if (hoursB === '12') {
        hoursB = 0;
      } else {
        hoursB = Number(hoursB);
      }
    }
    dateB.setHours(hoursB);
    dateB.setMinutes(minutesB);
    dateB.setSeconds(secondsB);

    if (a === b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }

    if (dateB < dateA) {
      return 1;
    }

    if (dateB > dateA) {
      return -1;
    }
  }

  if (filterType?.convertTo === 'date') {
    const dateA = moment(a, 'MM/DD/YYYY').toDate();
    const dateB = moment(b, 'MM/DD/YYYY').toDate();

    if (dateA === dateB) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (dateB < dateA) {
      return -1;
    }
    if (dateB > dateA) {
      return 1;
    }
    return 0;
  }

  if (filterType?.type === 'number' || filterType?.type === 'boolean') {
    if (a === b) {
      return 0;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    if (!Number.isNaN(Number(a)) && !Number.isNaN(Number(b))) {
      if (Number(b) < Number(a)) {
        return -1;
      }
      if (Number(b) > Number(a)) {
        return 1;
      }
    } else {
      if (b < a) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
    }

    return 0;
  }

  if (a === b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  if (b < a) {
    return -1;
  }

  if (b > a) {
    return 1;
  }
  return 0;
};

/**
 *
 * Handles sorting table by given column in decending or ascending order.
 *
 * @param {String} order
 * @param {String} orderBy
 * @returns
 */
export const getComparator = (order, orderBy, dataTypes) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, dataTypes)
    : (a, b) => -descendingComparator(a, b, orderBy, dataTypes);

/**
 * IE11 Compatable Sort
 *
 * @param {Array} array
 * @param {Function} comparator
 * @returns
 */
export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
