import React, {forwardRef} from 'react';
import {ReactComponent as CalendarIcon} from '../../Icons/Calendar.svg';

const moment = require('moment');

const CustomInputDate = forwardRef(({value, onClick}, ref) => {
  const selectedToMomentDay = moment(value, 'MM-DD-YYYY').format(
    'DD MMM, YYYY',
  );
  return (
    <button type="button" onClick={onClick} ref={ref}>
      <div className="flex gap-2 items-center">
        <CalendarIcon />
        <p className="text-[#70769B] text-sm leading-[17px]">
          {selectedToMomentDay ?? value}
        </p>
      </div>
    </button>
  );
});


export default CustomInputDate;