const light = {
  mainBackground: '#fff',
  mainSidebarBackground: '#fff',
  menuItemBackground: '#fff',
  menuItemBorderActive: '#3B7E2E',
  menuItemBackgroundActive: '#3B7E2E',
  menuItemTextPrimary: '#9AA2AC',
  menuItemTextActive: '#fff',
  headerText: '#464b45',
};
export default light;
