import themes from './index';

export const mapTheme = (variables) => ({
  '--main-background': variables.mainBackground || '',
  '--background-menu': variables.mainSidebarBackground || '',
  '--background-menu-item': variables.menuItemBackground || '',
  '--background-menu-item-active': variables.menuItemBackgroundActive || '',
  '--border-menu-item-active': variables.menuItemBorderActive || '',
  '--color-menu-text': variables.menuItemTextPrimary || '',
  '--color-menu-text-active': variables.menuItemTextActive || '',
  '--color-header-text': variables.headerText || '',
});

export const extend = (extending, newTheme) => ({...extending, ...newTheme});
/**
 * Helper function to set a new theme
 *
 * @param {string} theme The name of the theme to be set
 *
 * @return {void}
 */
export const applyTheme = (theme) => {
  const themeObject = mapTheme(themes[theme]);
  console.log('themeObject', themeObject);
  if (!themeObject) return;
  const root = document.documentElement;
  Object.keys(themeObject).forEach((property) => {
    if (property === 'name') {
      return;
    }
    root.style.setProperty(property, themeObject[property]);
    localStorage.setItem('theme', theme);
  });
};
