/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import React, {useEffect, useRef} from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {object} from 'prop-types';

const NumAbbr = require('number-abbreviate');

const getBackgroundColor = (rowValue, dataType) => {
  const {displayOptions, key} = dataType;

  if (!displayOptions || !Object.keys(displayOptions ?? {}).length) return {};
  const {formula} = displayOptions;
  let rowStyle = {};
  const rowVal = rowValue[key]?.value ?? rowValue[key];
  if (formula?.length) {
    formula.forEach((f) => {
      const {operations, style} = f;
      operations.forEach((o) => {
        const {operator, value} = o;
        if (operator === '=' && rowVal === value) {
          rowStyle = {...(rowStyle ?? {}), ...(style ?? {})};
        }
      });
    });
  }
  return rowStyle;
};

const displayRowValue = (rowValue, dataType) => {
  const {display, key, iconType, type} = dataType;
  const formatPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (!rowValue || (!rowValue[key]?.value && !rowValue[key])) return '';

  const {value, style} = rowValue[key];
  const curVal = value ?? rowValue[key];
  if (!curVal) return '';

  // const formatPercentage = new Intl.NumberFormat('en-US', {
  //     style: 'percent',
  // });

  const formatNumber = new Intl.NumberFormat('en-US', {
    currency: 'USD',
  });

  const baseClass =
    Object.keys(style ?? {})?.length && Object.keys(style ?? {}).includes('backgroundColor')
      ? 'color-item '
      : 'no-color-item ';

  switch (display) {
    case 'percentage':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {`${curVal}%`}{' '}
        </span>
      ) : null;
    case 'icon':
      return curVal ? (
        <span className="text-sky-400" style={style ?? {}}>
          <i className={iconType} />
        </span>
      ) : null;
    case 'custom':
      return curVal ? ( // eslint-disable-next-line
        <span className={baseClass} style={style ?? {}} dangerouslySetInnerHTML={{__html: curVal}} />
      ) : null;
    case 'money-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {`$${NumAbbr(Number(curVal), 2)}`.toUpperCase()}{' '}
        </span>
      ) : null;
    case 'number-short':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {`${NumAbbr(Number(curVal), 2)}`.toUpperCase()}{' '}
        </span>
      ) : null;
    case 'money':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {formatPrice.format(Number(curVal))}{' '}
        </span>
      ) : null;
    case 'number':
      return curVal && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {formatNumber.format(Number(curVal))}{' '}
        </span>
      ) : null;
    case 'tags':
      return curVal?.length && Array.isArray(curVal) ? (
        <div className="grid auto-cols-max gap-y-2 gap-x-1 justify-center">
          {curVal.map((tag) => (
            <div className="flex items-center justify-center" key={tag}>
              <span
                className={`${
                  Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
                } relative inline mx-auto items-center justify-center rounded-full border border-gray-300 px-2 py-0.5 hover:bg-slate-300`}
              >
                <div
                  className={`${
                    Object.keys(style ?? {}).length ? '' : 'no-style'
                  } text-sm font-medium text-gray-900 tag-name`}
                  style={style ?? {}}
                >
                  {tag}
                </div>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span
            className={`${curVal?.length ? 'border border-gray-300 ' : ''} ${
              Object.keys(style ?? {}).length ? '' : 'no-style-wrap'
            } relative inline mx-auto items-center justify-center rounded-full px-2 py-0.5 hover:bg-slate-300`}
          >
            <div
              className={`${
                Object.keys(style ?? {}).length ? '' : 'no-style'
              } text-sm font-medium text-gray-900 tag-name`}
              style={style ?? {}}
            >
              {curVal}
            </div>
          </span>
        </div>
      );
    case 'bullish-bearish':
      return curVal ? (
        <span
          className={baseClass}
          style={{
            ...(getBackgroundColor(rowValue, dataType) ?? {}),
            ...(style ?? {}),
          }}
        >
          {curVal}
        </span>
      ) : null;
    default:
      return type === 'number' && !Number.isNaN(Number(curVal)) ? (
        <span className={baseClass} style={style ?? {}}>
          {' '}
          {formatNumber.format(Number(curVal))}{' '}
        </span>
      ) : key === 'Symbol' ? (
        <span style={style ?? {}} className={`${baseClass} symbol-item`}>
          {' '}
          {curVal}{' '}
        </span>
      ) : (
        <span style={style ?? {}} className={`${baseClass + (key === 'Symbol' ? 'symbol-item' : '')}`}>
          {' '}
          {curVal}{' '}
        </span>
      );
  }
};

const ScannerItem = ({row, dataTypes, dispatch, group, multiScreen, getSymbolData}) => {
  const cellRef = useRef(null);
  useEffect(() => {
    if (cellRef.current && row.update) {
      cellRef.current.classList.remove('highlightChangeOut');
      cellRef.current.classList.add('highlightChange');
      setTimeout(() => {
        if (cellRef.current) {
          cellRef.current.classList.remove('highlightChange');
          cellRef.current.classList.add('highlightChangeOut');
        }
        dispatch({
          type: 'GROUP_UPDATED',
          payload: {keyId: row?.keyId, group: group.group},
        });
      }, 1000);
    }
  }, [row]);
  const handleClickSymbol = (key = null, symbol = null) => {
    if (symbol && key === 'Symbol') {
      getSymbolData(symbol, group);
    }
  };
  return (
    <TableRow hover tabIndex={-1} ref={cellRef}>
      {dataTypes.map((dataType, index) => (
        <TableCell
          onClick={() => {
            handleClickSymbol(dataType.key, row?.Symbol?.value);
          }}
          key={`${row ? row._id?.value : ''}-${dataType.key}-${row?.Symbol?.value}-${dataType.type}-${index * 2}`}
          align={
            dataType.type === 'number' || (multiScreen && dataType.key === 'Weeklies')
              ? 'right'
              : dataType.type === 'string' && dataType.display !== 'bullish-bearish'
              ? 'left'
              : 'center'
          }
          className={`${dataType.convertTo === 'time' ? 'whitespace-nowrap' : 'whitespace-nowrap'} ${
            dataType.key === 'Symbol' ? 'cursor-pointer' : ''
          }`}
        >
          <span className="scanner-item">{displayRowValue(row, dataType)}</span>
        </TableCell>
      ))}
    </TableRow>
  );
};

export default React.memo(ScannerItem, (prev, next) => JSON.stringify(prev) === JSON.stringify(next));
