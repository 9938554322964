const dark = {
  mainBackground: '#1A1B25',
  mainSidebarBackground: '#252634',
  menuItemBackground: '#252634',
  menuItemBorderActive: '#3B7E2E',
  menuItemBackgroundActive: '#252634',
  menuItemTextPrimary: '#9AA2AC',
  menuItemTextActive: '#fff',
  headerText: '#fff',
};
export default dark;
