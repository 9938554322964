/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */

import {setupSettings, updateFilters, updateFilteredData, formatDataForTable, join} from '../Helpers/formatData';

const reducer = (state, action) => {
  /**
   * @typedef {Object} SearchValue
   * @property {Array} additionalFilters
   * @property {Array} filters
   * @property {Array} boolFilters
   */

  /**
   * Group Object type definition
   * @typedef {Object} Group
   * @property {String} orderBy
   * @property {String} groupTitle
   * @property {String} order
   * @property {Number} rowsPerPage
   * @property {SearchValue} searchValue
   * @property {Array} dataTypes
   * @property {Array} data
   * @property {String} filteredData
   * @property {boolean} showBearBullFilter
   * @property {Number} page
   * @property {null | string} activeFilter
   * @property {String} group
   * @property {Array} orderTypes
   */
  const defaultGroupObj = {
    orderBy: '',
    groupTitle: '',
    order: 'asc',
    rowsPerPage: 25,
    searchValue: {
      additionalFilters: [],
      filters: [],
      boolFilters: [],
      bearBullFilters: [],
    },
    dataTypes: [],
    data: [],
    filteredData: [],
    showBearBullFilter: false, // move this into search values
    page: 0, // set to 0 at the end whenever filter is updated
    activeFilter: null, // move into search values
    group: '',
    orderTypes: [],
    selectedDate: null,
    showDateSelector: false,
  };

  const {type: actionType, payload} = action;
  const acc = [{year: 'numeric'}, {month: 'numeric'}, {day: 'numeric'}];
  const today = join(new Date(), acc, '-');

  switch (actionType) {
    case 'SET_USER': {
      const userInfo = payload;
      return {
        ...state,
        userData: userInfo,
      };
    }
    case 'SET_SCANNERS': {
      const scannersInfo = payload;
      return {
        ...state,
        scannersData: scannersInfo,
      };
    }
    case 'JOIN_GROUPS': {
      const {group: groupName, settings, data, type} = payload;
      const getGroup = state?.groupCollection[groupName] ? {...state?.groupCollection[groupName]} : null;

      const grpsClone = [...(state?.groups ?? [])];
      const foundInGroups = grpsClone?.findIndex((gr) => gr?.group === groupName);
      const datePicker = grpsClone[foundInGroups]?.date ?? today;
      const showDateSelector = grpsClone[foundInGroups]?.showDateSelector ?? false;

      // Create Group Object
      const groupObj = setupSettings(
        getGroup ?? {...defaultGroupObj, groupName, selectedDate: datePicker},
        groupName,
        settings,
        data,
        type ?? 'tickalert',
        false,
        datePicker,
        showDateSelector,
      );

      // Update Group Array from params
      if (foundInGroups > -1) {
        grpsClone[foundInGroups] = {
          ...grpsClone[foundInGroups],
          joined: true,
        };
      }

      return {
        ...state,
        groupCollection: {
          ...(state?.groupCollection || {}),
          [groupObj.group]: groupObj,
        },
        groups: [...grpsClone],
      };
    }

    case 'RESET_GROUPS': {
      const {group: groupName, settings, data, type} = payload;
      const getGroup = state?.groupCollection[groupName] ? {...state?.groupCollection[groupName]} : null;
      if (!getGroup) return state;

      // Create Group Object
      const groupObj = setupSettings(
        getGroup,
        groupName,
        settings,
        data,
        type ?? 'tickalert',
        true,
        getGroup?.selectedDate ?? today,
        getGroup?.showDateSelector ?? false,
      );

      return {
        ...state,
        groupCollection: {
          ...(state?.groupCollection || {}),
          [groupObj.group]: groupObj,
        },
      };
    }
    case 'UPDATE_TICKALERT': {
      const {data, group: groupName} = payload;
      const findGroup = state?.groupCollection[groupName];
      if (!findGroup) return state;
      if (!findGroup.isCurrentDate) {
        console.log('Not updating: currently viewing previous date');
        return state;
      }
      const formattedData = formatDataForTable(data, findGroup?.dataTypes, findGroup?.type, true);
      const newData = {...findGroup?.data, ...formattedData};
      if (findGroup?.limit && data) {
        const keys = Object.keys(newData);
        const dataToArray = keys.reduce((ac, key) => [...(ac || []), newData[key]], []);
        findGroup.data = formatDataForTable(
          dataToArray.length > findGroup?.limit
            ? [...dataToArray].reverse().slice(0, findGroup?.limit).reverse()
            : dataToArray,
          findGroup?.dataTypes,
          findGroup?.type,
        );
      } else {
        findGroup.data = newData;
      }
      findGroup.filteredData = updateFilteredData(findGroup);
      return {
        ...state,
        groupCollection: {...state.groupCollection, [groupName]: findGroup},
      };
    }

    case 'CLEAR_GROUP_COLLECTION': {
      return {
        ...state,
        groupCollection: {},
      };
    }

    case 'UPDATE_STATEVIEW': {
      const {data, group: groupName} = payload;
      const findGroup = state?.groupCollection[groupName];
      if (!data || !data.length || !findGroup) return state;
      if (!findGroup.isCurrentDate) {
        console.log('Not updating: currently viewing previous date');
        return state;
      }
      const formattedData = formatDataForTable(data, findGroup?.dataTypes, findGroup?.type);

      let newData = {...findGroup.data};
      data.forEach((update) => {
        newData = {
          ...newData,
          [update?.Symbol?.value ?? update?.Symbol]: {
            ...formattedData[update?.Symbol?.value ?? update?.Symbol],
            update: true,
          },
        };
      });

      if (findGroup?.limit && data) {
        const keys = Object.keys(newData);
        const dataToArray = keys.reduce((ac, key) => [...(ac || []), newData[key]], []);
        findGroup.data = formatDataForTable(
          dataToArray.length > findGroup?.limit
            ? [...dataToArray].reverse().slice(0, findGroup?.limit).reverse()
            : dataToArray,
          findGroup?.dataTypes,
          findGroup?.type,
        );
      } else {
        findGroup.data = newData;
      }

      findGroup.filteredData = updateFilteredData(findGroup);
      return {
        ...state,
        groupCollection: {...state.groupCollection, [groupName]: findGroup},
      };
    }
    case 'GROUP_UPDATED': {
      const {keyId, group: groupName} = payload;
      const findGroup = state?.groupCollection[groupName];
      if (!keyId || !keyId.length || !findGroup) return state;
      findGroup.data[keyId] = {...findGroup.data[keyId], update: false};

      return {
        ...state,
        groupCollection: {...state.groupCollection, [groupName]: findGroup},
      };
    }
    case 'STATE_VIEW_DELETE': {
      const {data, group: groupName} = payload;
      const findGroup = state?.groupCollection[groupName];
      if (!data || !data.length || !findGroup) return state;
      if (!findGroup.isCurrentDate) {
        console.log('Not deleting: currently viewing previous date');
        return state;
      }
      const dataKeys = Object.keys(findGroup?.data);
      const toRemove = data.map((remove) => remove?._id?.value);
      const symbolsToRemove = dataKeys.reduce((prev, next) => {
        if (!toRemove.includes(findGroup?.data[next]?._id?.value)) {
          return {...(prev || {}), [next]: findGroup?.data[next]};
        }
        return prev;
      }, {});
      findGroup.data = {...symbolsToRemove};
      findGroup.filteredData = updateFilteredData(findGroup);

      return {
        ...state,
        groupCollection: {...state.groupCollection, [groupName]: findGroup},
      };
    }
    case 'UPDATE_FILTER_VALUES': {
      const {group, updatedValue, key, minMax, field} = payload;
      const {group: groupName} = group;
      const groupToUpdate = state?.groupCollection[groupName] ?? null;
      if (!groupToUpdate) return state;
      const updatedFilterValues = updateFilters(updatedValue, key, minMax, groupToUpdate);
      return {
        ...state,
        groupCollection: {
          ...state.groupCollection,
          [group?.group]: updatedFilterValues,
        },
      };
    }
    case 'SET_FILTER': { // To be combined with update filter values to form one filter query
      const {group, filterBy} = payload;
      const {group: groupName} = group;
      const groupToUpdate = state?.groupCollection[groupName] ?? null;
      if (!groupToUpdate) return {...state};
      groupToUpdate.page = 0;
      if (groupToUpdate?.activeFilter === filterBy) {
        groupToUpdate.activeFilter = null;
        groupToUpdate.filteredData = updateFilteredData(groupToUpdate);
        return {
          ...state,
          groupCollection: {
            ...state.groupCollection,
            [groupToUpdate.group]: groupToUpdate,
          },
        };
      }

      groupToUpdate.activeFilter = filterBy;
      groupToUpdate.filteredData = updateFilteredData(groupToUpdate);

      return {
        ...state,
        groupCollection: {
          ...state.groupCollection,
          [groupToUpdate.group]: groupToUpdate,
        },
      };
    }
    case 'SET_PAGE': {
      const {group, page} = payload;
      const {group: groupName} = group;
      const groupToUpdate = state?.groupCollection[groupName] ?? null;
      if (!groupToUpdate) return state;
      groupToUpdate.page = page;
      return {
        ...state,
        groupCollection: {
          ...state.groupCollection,
          [groupToUpdate.group]: groupToUpdate,
        },
      };
    }
    case 'SET_ORDERBY': {
      const {group, order, orderBy} = payload;
      const {group: groupName} = group;
      const groupToUpdate = state?.groupCollection[groupName] ?? null;
      if (!groupToUpdate) return state;
      groupToUpdate.orderBy = orderBy;
      groupToUpdate.order = order;
      groupToUpdate.page = 0;
      return {
        ...state,
        groupCollection: {
          ...state.groupCollection,
          [groupToUpdate.group]: groupToUpdate,
        },
      };
    }
    case 'SET_ROWS_PER_PAGE': {
      const {group, rowsPerPage} = payload;
      const {group: groupName} = group;
      const groupToUpdate = state?.groupCollection[groupName] ?? null;
      if (!groupToUpdate) return state;
      groupToUpdate.rowsPerPage = rowsPerPage;
      groupToUpdate.page = 0;
      return {
        ...state,
        groupCollection: {
          ...state.groupCollection,
          [groupToUpdate.group]: groupToUpdate,
        },
      };
    }
    case 'SET_CONNECTED':
      return {...state, isConnected: true, didConnect: true};
    case 'SET_CONNECTED_STATUS':
      return {...state, loadingGroups: false};
    case 'SET_DISCONNECTED': {
      return {...state, isConnected: false};
    }
    case 'SET_INITIALIZED': {
      return {...state, initialized: true};
    }
    case 'SET_CLIENT_DISCONNECTED': {
      const joinedGroups = state?.groups?.map((group) => ({
        ...group,
        sentJoin: false,
        joined: false,
      }));
      return {
        ...state,
        groupCollection: {},
        groups: joinedGroups,
        isConnected: false,
      };
    }
    case 'EMIT_JOIN': {
      return {...state, groups: payload.joinedGroups};
    }
    case 'SETUP_SOCKET': {
      return {...state, wsSocket: payload.socket};
    }
    case 'CHANGE_GROUP_DATE': {
      const {group, newDate} = payload;
      if (!group || !newDate || !state.isConnected) return state;
      const {type: groupType, group: groupName} = group;
      const groupsArrayClone = [...(state?.groups ?? [])];
      const foundInGroups = groupsArrayClone?.findIndex((gr) => gr?.group === group?.group);
      const getGroup = state?.groupCollection[groupName] ? {...state?.groupCollection[groupName]} : null;
      if (foundInGroups <= -1 || !getGroup) return state;

      groupsArrayClone[foundInGroups] = {
        ...groupsArrayClone[foundInGroups],
        date: newDate,
      };

      getGroup.date = newDate;
      getGroup.page = 0;
      getGroup.isCurrentDate = newDate === today && getGroup?.showDateSelector;
      const scannerType = groupType ? decodeURIComponent(groupType) : 'tickalert';

      const groupToJoin = {
        group: `${groupName ? decodeURIComponent(groupName) : ''}`,
        date: newDate,
      };

      if (state?.wsSocket) {
        state?.wsSocket?.emit(`${scannerType}:join`, groupToJoin);
      }

      return {
        ...state,
        groupCollection: {
          ...(state?.groupCollection || {}),
          [groupName]: getGroup,
        },
        groups: groupsArrayClone,
      };
    }
    case 'RECONNECT_SOCKET': {
      const {groups, wsSocket} = state;
      if (!wsSocket || !groups) return state;
      if (wsSocket) {
        groups?.forEach((group) => {
          const {group: rbGroup, type: rbType, date} = group;
          const scannerType = rbType ? decodeURIComponent(rbType) : 'tickalert';
          const groupToJoin = {
            group: `${decodeURIComponent(rbGroup)}`,
          };
          if (date) {
            groupToJoin[date] = date ?? today;
          }
          console.log('joining ', rbGroup);
          wsSocket?.emit(`${scannerType}:join`, groupToJoin);
          return group;
        });
      }
      return state;
    }
    case 'SET_SYMBOL_TO_CHART': {
      const {symbol} = payload;
      return {...state, symbolToChart: symbol};
    }
    case 'SHOW_CHART': {
      const {symbolData, chartSettings} = payload;
      console.log('chartsettigns', chartSettings);
      return {
        ...state,
        symbolData,
        chartSettings,
        showChart: true,
      };
    }
    case 'CLOSE_CHART': {
      return {
        ...state,
        showChart: false,
        symbolData: [],
        symbolToChart: null,
      };
    }
    default:
      console.log(`Unhandled action type: ${actionType}`);
      throw new Error(`Unhandled action type: ${actionType}`);
  }
};

export default reducer;
