import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

const BearishBullishButtons = ({field, activeFilter, group, dispatch}) => (
  <div className="flex">
    <span className='flex text-xs text-header-color items-center pr-2'>{field?.title}</span>
    <ButtonGroup
      variant="outlined"
      aria-label="outlined button group"
      className="flex gap-x-1 gap-y-2 items-center filter-btns"
    >
      <Button
        className={`bearish ${activeFilter?.selected?.includes('bearish') ? 'active' : ''} sm:text-sm`}
        onClick={() => dispatch({type: 'UPDATE_FILTER_VALUES', payload: {updatedValue: 'bearish', group, key: field?.key, field}})}
        content={
          <span className="flex items-center gap-x-2">
            <FontAwesomeIcon icon={faFilter} /> Bearish
          </span>
        }
      />
      <Button
        className={`bullish ${activeFilter?.selected?.includes('bullish') ? 'active' : ''} sm:text-sm`}
        onClick={() => dispatch({type: 'UPDATE_FILTER_VALUES', payload: {updatedValue: 'bullish', group, key: field?.key, field}})}
        content={
          <span className="flex items-center gap-x-2">
            <FontAwesomeIcon icon={faFilter} /> Bullish
          </span>
        }
      />
    </ButtonGroup>
  </div>
);

export default BearishBullishButtons;
