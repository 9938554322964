import base from './base';
import dark from './dark';
import light from './light';
/**
 * The default theme to load
 */
export const DEFAULT_THEME = 'light';
const themes = {
  dark: {...dark},
  light: {...light},
};
export default themes;
