import React, {Fragment} from 'react';
import {Transition, Popover} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/outline';

const MultiSelect = ({
  filter,
  handleUpdateMultiSelect,
  label,
  group,
  multiScreen,
}) => (
  <div className="flex flex-col grow w-1/6 gap-2">
    <div className="flex items-center justify-end">
      <Popover as="div" className="relative inline-block text-left">
        <div>
          <Popover.Button
            style={{background: 'rgba(148, 196, 242, 0.1)'}}
            className={`${
              multiScreen ? '' : 'px-4 py-2 '
            } whitespace-nowrap filter-label items-center px-2 py-1 inline-flex justify-center w-full rounded-[50px] border-none  text-[#70769B] border-gray-300 shadow-sm bg-white text-md font-medium  hover:bg-gray-50 focus:outline-none focus:ring-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-transparent`}
          >
            {label}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5"
              aria-hidden="true"
            />
          </Popover.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="w-auto origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white z-50 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="divide-y divide-gray-200">
              <fieldset className="px-4 py-2 gap-3 flex flex-col">
                {filter?.filterable?.options?.map((field) => (
                  <div
                    className="relative flex items-center py-2"
                    key={field?.key ?? field}
                  >
                    <div className="min-w-0 flex-1 text-md">
                      <label
                        htmlFor={field?.key ?? field}
                        className="font-medium text-gray-700 checkbox-label"
                      >
                        {field}
                      </label>
                    </div>
                    <div className="ml-3 flex items-center h-5">
                      <input
                        onChange={(e) =>
                          handleUpdateMultiSelect(
                            e.target.checked,
                            filter?.key,
                            field,
                            group,
                          )
                        }
                        checked={filter?.value.includes(field)}
                        id={field?.key ?? field}
                        aria-describedby={field?.key ?? field}
                        name={field?.key ?? field}
                        type="checkbox"
                        className="focus:ring-transparent h-4 w-4 text-sky-500 border-gray-300 rounded"
                      />
                    </div>
                  </div>
                ))}
              </fieldset>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  </div>
);

export default MultiSelect;
