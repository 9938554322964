import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import LoadingUser from '../Components/LoadingUser/LoadingUser';
import Sidebar from '../Components/Sidebar/Sidebar';
import useApplicationData from '../Hooks/useApplicationData';

const Dashboard = (props) => {
  const [
    {
      initialized,
      isConnected,
      didConnect,
      scannersData,
      userData,
      groupCollection,
      groups,
      loadingGroups,
      wsSocket,
      chartSettings,
      showChart,
      symbolData,
      symbolToChart,
    },
    dispatch,
  ] = useApplicationData();
  const {theme, setTheme} = props;
  return initialized ? (
    isConnected ? (
      <div className="flex w-full">
        <Sidebar scannersData={scannersData} theme={theme} setTheme={setTheme} />
        <div className="flex-grow w-5/6 pt-9 pl-[39px] pr-[33px] relative main-container">
          <Outlet
            context={[
              scannersData,
              userData,
              groupCollection,
              groups,
              isConnected,
              loadingGroups,
              wsSocket,
              chartSettings,
              showChart,
              symbolData,
              symbolToChart,
              dispatch,
            ]}
          />
        </div>
      </div>
    ) : didConnect ? (
      <LoadingUser message="Reconnecting..." />
    ) : (
      <Navigate replace to="/access-denied" />
    )
  ) : (
    <LoadingUser message="Loading Scanners..." />
  );
};

export default Dashboard;
