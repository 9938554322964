import {useReducer, useEffect} from 'react';
import {io} from 'socket.io-client';
import reducer from './reducer';

const rbReducer = reducer;
const useApplicationData = () => {
  const [
    {
      groups,
      groupCollection,
      wsSocket,
      isConnected,
      didConnect,
      loadingGroups,
      initialized,
      chartSettings,
      showChart,
      symbolData,
      symbolToChart,
      ...state
    },
    dispatch,
  ] = useReducer(rbReducer, {
    groupCollection: {},
    groups: null,
    isConnected: false,
    didConnect: false,
    loadingGroups: true,
    initialized: false,
    wsSocket: null,
    updatedCount: 0,
    chartSettings: {},
    showChart: false,
    symbolData: {},
    symbolToChart: null,
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('token');
    if (!token) token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7InJlZmVycmVyX2lkIjoibW9kMzkyIiwibmFtZSI6IkdhYmUxIiwiZW1haWwiOiJnYWJyaWVsQGdyYXBoZW0uY2EiLCJkaXNwbGF5X2ljb24iOiJodHRwczovL2FwcC5yYWdpbmdidWxsLmNvbS9hc3NldHMvaW1nL2FkbWluX2F2YXRhci5wbmciLCJyb2xlIjoiYWRtaW4iLCJzY2FubmVycyI6eyJhY2Nlc3NpYmxlIjpbInNreW5ldC1keW5hbWljLXdhdGNobGlzdCIsInVudXN1YWwtb3B0aW9ucy1hY3Rpdml0eS1zY2FubmVyIiwiZGFyay1wb29sLXNjYW5uZXIiLCJzcXVlZXplLXNjYW5uZXIiLCJ0cmFkZS1vZi10aGUtZGF5LXNjYW5uZXIiLCJoaWdoLW9jdGFuZS1zY2FubmVyIiwiMzYwLXNjYW5uZXIiLCJwb3N0bWFya2V0LXVwLWdhcHMiLCJqdy1tb21lbnR1bS1zY2FubmVyIiwid2FsbC1zdHJlZXQtYm9va2llLXNjYW5uZXIiLCJzbWFsbC1jYXAtc25pcGVyIiwidHJlbmRpbmctc3RvY2tzIl0sInN1YnNjcmliYWJsZSI6W10sInN1YnNjcmliZWQiOltdfSwiZnJlZV9hY2Nlc3MiOnRydWV9LCJpYXQiOjI2Njg0NTUwMjIsImV4cCI6MjY2ODQ4MzgyMn0.eLAVIjHuq3FcrHhQAk3iVa8ax_aRlvycnqTJ4ivi1E4';
    if (token) {
      localStorage.setItem('scanner-sso', token);
      url.searchParams.delete('token');
      window.history.replaceState({}, window.document.title, url.href);
    } else {
      token = localStorage.getItem('scanner-sso');
    }

    if (!token) {
      console.log('Disconnected. Reason: No user token supplied.');
      dispatch({type: 'SET_INITIALIZED'});
      dispatch({type: 'SET_DISCONNECTED'});
    }
  }, [initialized, isConnected]);

  useEffect(() => {
    if (localStorage.getItem('scanner-sso') && !wsSocket) {
      const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        reconnectionDelayMax: 5000,
        auth: {
          token: localStorage.getItem('scanner-sso'),
        },
        transports: ['websocket', 'polling'],
      });

      dispatch({type: 'SETUP_SOCKET', payload: {socket}});
    }
  }, []);

  /**
   * Setup join and listen events
   * When disconnected, clear existing group data and wait for reconnection.
   */
  useEffect(() => {
    if (wsSocket) {
      console.log('wsSocket', wsSocket);
      wsSocket?.on('connect', () => {
        console.log('Connected to WS server:', wsSocket?.id, wsSocket?.connected);
        dispatch({type: 'SET_CONNECTED'});
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('disconnect', (reason) => {
        console.log('disconnected. Reason:', reason);
        dispatch({type: 'SET_DISCONNECTED'});
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          wsSocket?.connect();
          dispatch({type: 'SET_CLIENT_DISCONNECTED'});
        }
      });

      wsSocket?.on('error', (error) => {
        console.error('Server socket.io error:', error);
      });

      wsSocket?.on('connect_error', (error) => {
        console.log('Connect Error:', error);
        dispatch({type: 'SET_INITIALIZED'});
      });

      wsSocket?.on('settings:scanner-access', (scannersData) => {
        dispatch({type: 'SET_SCANNERS', payload: scannersData});
      });

      wsSocket?.on('settings:user', (userData) => {
        dispatch({type: 'SET_USER', payload: userData});
      });

      wsSocket?.on('settings:scanner-access', (scanners) => {
        console.log('settings:scanner-access', scanners);
      });

      wsSocket?.on(`stateview:update`, (res) => {
        // console.log('update stateview', res);
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_STATEVIEW', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:update`, (res) => {
        console.log('update tickalert', res);
        const {data, group} = res;
        if (!group || !data) return;
        dispatch({type: 'UPDATE_TICKALERT', payload: {group, data}});
      });

      wsSocket?.on(`stateview:refresh`, (res) => {
        console.log('stateview:refresh', res);
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`tickalert:refresh`, (res) => {
        console.log('tickalert:refresh', res);
        const {group, data} = res;
        if (!group || !data) return;
        dispatch({type: 'RESET_GROUPS', payload: {group, data}});
      });

      wsSocket?.on(`stateview:join`, (res) => {
        console.log('stateview:join', res);
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'stateview'},
        });
      });

      wsSocket?.on(`stateview:delete`, (res) => {
        console.log('stateview:delete', res);
        const {group, data, type} = res;
        if (!group || !data) {
          return;
        }
        dispatch({
          type: 'STATE_VIEW_DELETE',
          payload: {group, data, type},
        });
      });

      wsSocket?.on(`tickalert:join`, (res) => {
        console.log('tickalert:join', res);
        const {group, settings, data} = res;
        if (!group || !settings || !data) {
          return;
        }
        dispatch({
          type: 'JOIN_GROUPS',
          payload: {group, settings, data, type: 'tickalert'},
        });
      });

      wsSocket?.on(`chart:get`, (res) => {
        const {settings, masterData} = res;
        if (!masterData) {
          return;
        }
        dispatch({
          type: 'SHOW_CHART',
          payload: {symbolData: masterData, chartSettings: settings},
        });
      });

      wsSocket.io.on('reconnection_attempt', () => {
        console.log('reconnection_attempt');
      });

      wsSocket?.io?.on('reconnect', () => {
        console.log('wsSocket.io reconnect');
        dispatch({type: 'RECONNECT_SOCKET'});
      });
    }
    return () => {
      wsSocket?.close();
      dispatch({type: 'SET_CLIENT_DISCONNECTED'});
    };
  }, [wsSocket]);

  // Render scanners when all groups are joined and set up.
  useEffect(() => {
    const joinedGroups = [...(groups ?? [])].filter(
      (gr) => gr.joined && Object.keys(groupCollection || {})?.includes(gr.group),
    );
    if (
      groups?.length === Object.keys(groupCollection || {}).length &&
      groups?.length &&
      Object.keys(groupCollection || {}).length &&
      joinedGroups.length &&
      loadingGroups
    ) {
      dispatch({type: 'SET_CONNECTED_STATUS', payload: {loadingGroups: false}});
    }
  }, [groupCollection, groups]);

  return [
    {
      groups,
      groupCollection,
      wsSocket,
      isConnected,
      didConnect,
      loadingGroups,
      initialized,
      symbolToChart,
      chartSettings,
      showChart,
      symbolData,
      ...state,
    },
    dispatch,
  ];
};

export default useApplicationData;
